import axios from "axios";
import { showScreenLoading, hideScreenLoading } from "@/utils/loading";
import { Notification, MessageBox, Message } from "element-ui";
import router from "@/router";
import { refreshToken } from "@/airencaiapi/user";

//不需要头部的请求
let filterate = [
  "/RecruitingProcess/getPlanData",
  "/ResumeDelivery/createExamineAndApprove",
  "/ManpowerTable/fillInTheReinstatementForm",
  "/ManpowerTable/obtainEntryApproval",
  "/user/register",
  "/user/login",
  "/user/createCompany",
  "/user/CreateUser",
  "/ManpowerTable/createJobRegistration",
  "/RecruitingProcess/createJobRegistration",
  "/RecruitingProcess/getJobRegistration",
  "/biographicalNotes/getResumeJobRegistration",
  "/biographicalNotes/getResumeCompletion",
  "/biographicalNotes/changeBiographical",
  "/biographicalNotes/UploadResumeAndProcess",
  "/RecruitingProcess/submitTheOnboardingApprovalForm",
  "/VideoInterviewQuestions/createVideoInterviewQuestions",
  "/VideoInterviewQuestions/getProfessionalValuesService",
  "/VideoInterviewQuestions/getSelf",
  "/VideoInterviewQuestions/getVideoInterviewResults",
  "/user/sendVerificationCodeThroughMobilePhoneNumber",
  "/user/loginWithPhoneNumber",
  "/user/refreshToken",
  "/reportForms/getReportImage",
  '/presentation/getPresentationInfo'
];

const api = axios.create({
  baseURL: "https://java.airencai.com.cn",
  // baseURL: "http://192.168.1.33:9089",
  timeout: 50000,
});

let time = true;
api.interceptors.response.use(
  async (response) => {
    hideScreenLoading();
    time = false;
    let path = router.currentRoute.path;
    // 判断是不是过期了过期去登录界面
    if (response.data.code == 403) {
      if (localStorage.getItem("refreshToken")) {
        const { data, code } = await refreshToken(
          localStorage.getItem("refreshToken")
        );
        if (code == 200) {
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("token", data.token);
          return api(response.config);
        } else {
          Message.error(response.data.msg);
          router.push("/login");
        }
      } else {
        if (path === '/AiTalentProfileReport') {
          router.push("/AiTalentProfileReport");
        } else {
          Message.error(response.data.msg);
          router.push("/login");
        }

      }
    } else if (response.data.code == 1000) {
      Message.error(response.data.msg);
      router.push("/login");
    }
    return response.data != null ? response.data : response;
  },
  function (err) {
    if (!err.response.status) {
      Notification.error({ title: "提醒", message: "服务器出了点小错误" });
      hideScreenLoading();
      return;
    }
    switch (err.response.status) {
      case 401:
        MessageBox.alert("登陆已过期，请关闭当前窗口重新进入");
        break;
      case 403:
        Message.error(response.data.msg);
        router.push("/login");
        break;
      case 500:
        Notification.error({ title: "提醒", message: "服务器出了点小错误" });
        break;
      case 404:
        Notification({
          title: "提醒",
          message: "未找到，检查参数",
          type: "warning",
        });
        break;
      default:
        return Promise.reject(err);
    }
  }
);
api.interceptors.request.use((request) => {
  time = true;
  //提示加载两秒自动关闭
  showScreenLoading({ text: "正在加载中......" });
  let isFilterate = false;
  for (let index = 0; index < filterate.length; index++) {
    const element = filterate[index];
    if (request.url.indexOf(element) != -1) {
      isFilterate = true;
      break;
    }
  }
  if (isFilterate) {
    return request;
  }
  if (localStorage.getItem("token")) {
    request.headers["token"] = localStorage.getItem("token");
  }
  return request;
});

export default api;
