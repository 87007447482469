<template>
  <div id="app">
    <router-view />
    <div id="luckysheet" style="display: none"></div>
  </div>
</template>

<script>
import Conversion from "@/utils/pinyinConversion";
import { getPermissionList } from "@/utils/initialize";
import CryptoFiles from "@/utils/AES";
export default {
  data() {
    return {
      largeScreen: null,
    };
  },
  watch: {
    "$store.state.user": {
      immediate: true,
      handler(val) {
        console.log('走了这里吗',val.id);
            
        let path = this.$route.path;
        if (val.id == undefined) {
          if (localStorage.getItem("arcUser") == null) {
            console.log('当前路由信息1：',path);

            // this.$router.push("/login");
          } else {
            // 获取当前路由path
            console.log('当前路由信息2：',path);
            getPermissionList(val.id, this);
          }
          window.pako_zip = this.$pako_zip;
          window.pako_unzip = this.$pako_unzip;
        }
      },
    },
  },
  async mounted() {
 
   this.$speech.onstart = () => {
      this.$recognition.stop();
    };
    this.$recognition.lang = window.navigator.language || "en-US";
    this.$recognition.interimResults = false;
    this.$recognition.continuous = true;
    this.$recognition.start();
    this.$recognition.onresult = (event) => {
      const result = event.results[event.results.length - 1];
      this.$store.commit("SET_result", result[0].transcript);
      Conversion(result[0].transcript, this);
    };
    this.$speech.onend = () => {
      // console.log(this);
      this.$recognition.start();
    };
    document.addEventListener("fullscreenchange", (event) => {
      if (document.fullscreenElement) {
        this.largeScreen = true;
        this.$store.commit("SET_largeScreen", this.largeScreen);
      } else {
        this.largeScreen = false;
        this.$store.commit("SET_largeScreen", this.largeScreen);
      }
    });
  },
  methods: {
    init() { },
  },
};
</script>
<style lang="less">
@import url("@/assets/css/dialog.less");
@import url("../theme/app.css"); // 公共flex布局样式

.pulley {
  width: 100%;
  height: 76vh;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.menu_fold {
  img {
    height: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.elButton {
  background: linear-gradient(to right, #ffd1d1, #ff9b9b, #ffd1d1) !important;
  color: #fff !important;
  font-weight: 700 !important;
  padding: 0.6vw 1.5vw !important;
}

/deep/ .el-input__inner {
  border: 1px solid #fff !important;
}

.el-header:nth-child(1) {
  line-height: 0.7rem !important;
}

#luckysheet-tooltip-up {
  display: none;
}

// div {
//   font-size: 14px;
// }
.el-table {
  * {
    // font-size: 16px;
  }
}

.el-table th {
  padding: 0.14rem 0 !important;
}

.el-table td {
  padding: 0.068rem 0 !important;
}

.el-menu {
  //  height: 1rem !important;
  border-radius: 0.125rem 0.125rem 0 0;
}

.el-dialog__header {
  text-align: center;
}

.el-dialog__title {
  color: #fff !important;
}

.cell {
  text-align: center !important;
}

// *{
//   font-size: .175rem;
// }
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.el-main {
  &::-webkit-scrollbar {
    display: none;
  }
}

.elButton {
  border: none !important;

  span {
    margin: 0 !important;
  }
}

::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.15rem;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0.1rem #ff8383;
  background: linear-gradient(#ff8383, #f8e1e1);
  cursor: pointer;
}

html,
body,
#app {
  font-family: "Helvetica Neue", "Hiragino Sans GB", "WenQuanYi Micro Hei",
    "Microsoft Yahei", sans-serif;
  height: 100vh;
  padding: 0;
  margin: 0;
  font-size: 14px;
  //min-width: 1300px!important;
  //min-height: 600px;
}



a {
  text-decoration: none;
}

.dragDiv {
  position: absolute;
  z-index: 10;
  right: 0;
  //top: 200px;
  bottom: 0.625rem;
  width: 6.25rem;
  /*background-color: #19c3eb*/
}

#robot {
  //height: 80px;
  height: 130px;
  position: relative;
  float: right;
  /*width: 80px;
    background-image: url("./assets/airoboot.gif");
    background-size: 100% 100%;*/
}

.el-alert {
  width: 200px !important;
  padding: 18px 16px !important;
  position: absolute !important;
  right: 150px;
  top: -20px;
  z-index: 10;

  .el-alert__description {
    font-size: 14px !important;
  }
}

.chatting {
  position: absolute;
  z-index: 11;
  right: 20px;
  top: 94px;
}

.el-input__inner {
  background: #f9f6f6 !important;
}

.el-scrollbar__wrap {
  background: #f9f6f6 !important;
}

.el-textarea__inner {
  background: #f9f6f6 !important;
}
</style>
