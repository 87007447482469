import axios from "./airencaiHttp";
//登录接口
export function login(data) {
  return axios.post("/user/login", data);
}

export function refreshToken(token) {
  return axios.post("/user/refreshToken/"+token);
}

export function logout() {
  return axios.get("/user/logout");
}
//查询所有用户信息
export function userAll() {
  return axios.get("/user/userAll");
}

//查询所有用户信息
export function getUserCompany() {
  return axios.get("/user/getUserCompany");
}

//修改密码
export function changePassword(data) {
  return axios.post("/user/changePassword", data);
}

//导入职位效能
export function importFunctionalSystem(data) {
  return axios.post("/user/importFunctionalSystem", data);
}

export function changeUser(data) {
  return axios.post("/user/changeUser", data);
}

//获取职位体系
export function getFunctionalSystem(params) {
  return axios.get("/user/getFunctionalSystem", { params });
}

export function getUserFraction(params) {
  return axios.get("/user/getUserFraction", { params });
}

//逻辑批量删除职位体系
export function batchFunctionalSystem(data) {
  return axios.post("/user/batchFunctionalSystem", data);
}

//上传文件
export function upload(data) {
  return axios.post("/user/upload", data);
}

// //改变用户权限信息
// export  function changePermission (id,fun,permission){
//     return  axios.post("/user/changePermission",{"_id":id,"function":fun,permission})
// }

// //根据用户id改变表格列表信息
// export  function changeLuckySheetList(id,luckySheetList){
//     return  axios.post("/user/changeFunction",{"_id":id,luckySheetList})
// }

//通过公司id查询所有人员信息
export function getCompanyStaff(id) {
  return axios.get("/user/getCompanyAll/" + id);
}

export function getCompany(id) {
  return axios.get("/user/getCompany/" + id);
}
//创造用户
export function CreateUser(data) {
  return axios.post("/user/CreateUser", data);
}
//公司通过主公司创造分公司
export function createCompany(data) {
  return axios.post("/user/createCompany", data);
}
//通过公司id获取所有的分公司信息
export function getCompanyAll(id, current, pageSize) {
  return axios.get("/user/getCompanyStaff/" + id + "/" + current + "/" + pageSize);
}
//更改用户权限类型
export function changeType(data) {
  return axios.post("/user/changeType", data);
}
//更改用户职位权限
export function changeJobWeight(data) {
  return axios.post("/user/changeJobWeight", data);
}

//通过公司id获取所有的分公司信息
export function getFilialeAll(id) {
  return axios.get("/user/getFilialeAll/" + id);
}

//通过公司id获取所有的分公司信息
export function changeCode(data) {
  return axios.post("/user/changeCode", data);
}
//查询分公司所有员工
export function getFilialeStaff(id, current, pageSize) {
  return axios.get(
    "/user/getFilialeStaff/" + id + "/" + current + "/" + pageSize
  );
}
//新建一个招聘邮箱
export function newRecruitmentEmail(data) {
  return axios.post("/user/newRecruitmentEmail", data);
}
//删除一个招聘邮箱
export function deleteRecruitmentEmail(id) {
  return axios.get("/user/deleteRecruitmentEmail/" + id);
}
//根据用户id 查询 用户绑定的邮箱
export function getRecruitmentEmail(id) {
  return axios.get("/user/getRecruitmentEmail/" + id);
}
// //获取部门员工
// export function getDepartmentUser(userId){
//     return axios.get("/user/getDepartmentUser/"+userId)
// }
/**
 * 检验用户名
 * @param {*} userName
 * @returns
 */
export function verifyUsername(userName) {
  return axios.get("/user/verifyUsername/" + userName);
}
//获取部门员工
export function getSubordinates(userId) {
  return axios.get("/user/getSubordinates/" + userId);
}

/**
 * 更新头像
 * @param {*} data
 * @returns
 */
export function changeAvatar(data) {
  return axios.post("/user/changeAvatar", data);
}

//查询部门员工
export function userFuzzyQuery(params) {
  return axios.get("/user/userFuzzyQuery", { params });
}
// 查询部门
export function getDepartment(companyId) {
  return axios.get("/user/getDepartment/" + companyId);
}

// 查询部门
export function getDepartmentUser(data) {
  return axios.post("/user/getDepartmentUser", data);
}
//获取部门员工
export function pagingQueryUsers(data) {
  return axios.post("/user/pagingQueryUsers", data);
}

//获取自己全部信息
export function getUser(id) {
  return axios.get("/user/getUser/" + id);
}

//发送验证码
export function sendVerificationCodeThroughMobilePhoneNumber(phone) {
  return axios.get(
    "/user/sendVerificationCodeThroughMobilePhoneNumber/" + phone
  );
}

//发送验证码
export function loginWithPhoneNumber(data) {
  return axios.post("/user/loginWithPhoneNumber", data);
}

//发送验证码
export function changePasswordCode(phone) {
  return axios.get("/user/changePasswordCode/" + phone);
}

//发送验证码
export function changeSMSPassword(data) {
  return axios.post("/user/changeSMSPassword", data);
}

// 获取/user/generateUserQrCode
export function generateUserQrCode() {
  return axios.get("/user/generateUserQrCode");
}

// 生成小程序二维码 /user/generateQrCode
export function generateQrCode(data) {
  return axios.post("/user/generateQrCode", data);
}

/**
 * 生成面试应聘者拒绝id
 * @param {*} id 
 * @param {*} type
 * @returns
 * */
// /RecruitingProcess/generateInterviewRejection/{id}/{type}
export function generateInterviewRejection(id, type) {
  return axios.get(`/RecruitingProcess/generateInterviewRejection/${id}/${type}`);
}

// 发送offer邮件
export function sendOfferEmail(id,data) {
  return axios.post(`/RecruitingProcess/sendOfferMail/${id}`,data);
}